const links = [
	{
		path: '/navigator/about-pogrammes/',
		name: 'О программах',
		active: true,
	},
	{
		path: '/navigator/programmes/',
		name: 'Навигатор программ',
		active: true,
	},
	{
		path: '/navigator/events/',
		name: 'Мероприятия',
		active: false,
	},
	// {
	// 	path: '/navigator/content/',
	// 	name: 'Медиа',
	// 	active: false,
	// },
	// {
	// 	path: '/navigator/vacancies/',
	// 	name: 'Вакансии',
	// 	active: true,
	// },
	// {
	// 	path: '/navigator/',
	// 	name: 'Все',
	// 	active: true,
	// },
];

export default links;
