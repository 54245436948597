// Удаляет '/' из начала и конца
// Принимает либо 1 урл в виде строки, либо массив
export const denormalizeUrl = (urls) => {
	if (!urls) return '';

	if (typeof urls === 'string') {
		let newUrl = urls;
		if (newUrl.startsWith('/')) { newUrl = newUrl.slice(1); }
		if (newUrl.endsWith('/')) { newUrl = newUrl.slice(0, -1); }

		return newUrl;
	}

	if (!Array.isArray(urls)) {
		return '';
	}

	return urls.map((url) => {
		let newUrl = url;
		if (newUrl.startsWith('/')) { newUrl = newUrl.slice(1); }
		if (newUrl.endsWith('/')) { newUrl = newUrl.slice(0, -1); }

		return newUrl;
	});
};

// Добавляет '/' в конец для всех путей и в начало для относительных
export const normalizeUrl = (url) => {
	if (!url) return '/';

	let newUrl = url.toString();

	if (!newUrl.endsWith('/')) newUrl += '/';
	if (!newUrl.startsWith('/') && !newUrl.startsWith('h')) newUrl = `/${newUrl}`;

	return newUrl;
};

// Берет массив link. Смотрит на внутреннее поле объектов массив path
// И возвращает index объекта в массиве, который совпадает path
export const getLinkId = (path, links) => {
	let id = -1;
	links.forEach((link, index) => {
		const normalizeLink = normalizeUrl(link.path);
		const normalizePath = normalizeUrl(path);

		if (normalizePath === normalizeLink) id = index;
	});

	return id;
};
