<template>
	<div class="search-line_menu">
		<div class="container">
			<div class="swiper_menu">
				<div v-swiper:menuSwiper="options">
					<div class="swiper-wrapper">
						<div v-for="(link, index) in links" :key="index" class="swiper-slide">
							<a
								v-if="link.home"
								:href="link.path"
								class="home icon-home"
							/>
							<a
								v-else-if="link.active"
								:href="link.path"
								:class="{active: linkIsActive(link.path)}"
							>
								{{ link.name }}
							</a>
						</div>
					</div>
					<div class="event-button-prev icon-pin_left swiper-button-disable" />
					<div class="event-button-next icon-pin_right swiper-button-disable" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import { getLinkId } from '@/utils/urlHelper';

export default {
	name: 'SearchLineMenu',
	components: {
		Swiper,
		SwiperSlide,
	},
	directives: {
		swiper: directive,
	},
	props: ['links', 'currentPath'],
	data() {
		return {
			options: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				cssMode: false,
				loop: false,
				grabCursor: true,
				navigation: {
					nextEl: '.event-button-next',
					prevEl: '.event-button-prev',
				},
			},
			linkActiveButtons: [],
		};
	},
	methods: {
		linkIsActive(link) {
			const currentPath = this.removeQuery(this.currentPath) || this.removeQuery(this.$route.fullPath);
			return currentPath === this.removeQuery(link);
		},
		removeQuery(link) {
			if (link) {
				let path = link;
				let regMatch = path.match(/(.+)\?.+/);
				if (Array.isArray(regMatch) && regMatch.length > 1) {
					path = regMatch[1];
				}
				return path;
			}
			return link;
		}
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/search-line-menu";

</style>
